<template>
  <b-row class="no-gutters">
    <b-col class="col-12 col-md-8 col-lg-6">
      <h4 class="font-weight-normal text-muted title__2">
        {{ $t("paymentOptions") }}
      </h4>
      <b-card no-body class="p-3 mt-4">
        <credit-card-form @setFormValidationStatus="setFormValidationStatus" />
        <hr class="mx-n3 my-5" />
        <pay-pal-form
          :isFormValid="isFormValid"
          :isProjectValid="isProjectValid"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import CreditCardForm from "./../components/Checkout/CreditCardForm";
import PayPalForm from "./../components/Checkout/PayPalForm";
export default {
  components: {
    CreditCardForm,
    PayPalForm
  },
  data() {
    return {
      isFormValid: false
    };
  },
  computed: {
    isProjectValid() {
      return this.$store.getters["wizard/isProjectValid"];
    }
  },
  methods: {
    goToProjectReviewPage() {
      this.$router.push({ name: "Review" });
    },
    setFormValidationStatus(valid) {
      this.isFormValid = valid;
    }
  }
};
</script>

<style scoped></style>
