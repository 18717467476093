<template>
  <validation-observer ref="observer" v-slot="{ valid }">
    <!-- DUMMY BUTTON TO SET THE VALIDATION -->
    <b-button
      :disabled="setFormValidationStatus(valid)"
      style="display:none"
    ></b-button>
    <b-form>
      <b-row class="no-gutters">
        <b-col class="col-12 col-md-8 pr-md-3">
          <validation-provider
            :name="$t('company')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('company')"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="company"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12 col-md-4">
          <validation-provider
            :name="$t('VATNumber')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('VATNumber')"
              label-for="VATNumber"
            >
              <b-form-input
                id="VATNumber"
                v-model="VATNumber"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12">
          <validation-provider
            :name="$t('address')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="address"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12 col-sm-6 col-md-3 pr-sm-3">
          <validation-provider
            :name="$t('country')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('country')"
              label-for="country"
            >
              <b-form-select
                id="country"
                v-model="country"
                :state="getValidationState(validationContext)"
              >
                <b-form-select-option
                  v-for="(countryInfo, index) in countryOptions"
                  :key="index"
                  :value="countryInfo"
                >
                  {{ countryInfo.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12 col-sm-6 col-md-3 pr-sm-3">
          <validation-provider
            :name="$t('postalCode')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('postalCode')"
              label-for="postalCode"
            >
              <b-form-input
                id="postalCode"
                v-model="postalCode"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12 col-sm-8 col-md-4 pr-sm-3">
          <validation-provider
            :name="$t('city')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('city')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="city"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12 col-sm-4 col-md-2">
          <validation-provider
            :name="$t('province')"
            :rules="{ required: true, alpha: true, length: 2 }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('province')"
              label-for="province"
            >
              <b-form-input
                id="province"
                v-model="province"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12">
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('recipientCode')"
            label-for="recipientCode"
          >
            <b-form-input
              id="recipientCode"
              v-model="recipientCode"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('PEC')"
            label-for="PEC"
          >
            <b-form-input id="PEC" v-model="PEC"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('purchaseOrderNumber')"
            label-for="purchaseOrderNumber"
          >
            <b-form-input
              id="purchaseOrderNumber"
              v-model="purchaseOrderNumber"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <validation-provider
            :rules="{ checkboxRequired: true }"
            v-slot="validationContext"
          >
            <b-row class="no-gutters">
              <b-col class="col-auto">
                <b-form-checkbox
                  v-model="acceptTerms"
                  :state="getValidationState(validationContext)"
                >
                </b-form-checkbox>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col>
                {{ $t("creditCardTermsText") }}
                <b-link class="link text-red">{{
                  $t("creditCardTermsLink")
                }}</b-link>
              </b-col>
            </b-row>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import Utils from "./../../Utils";
export default {
  data() {
    return {
      countryOptions: Utils.COUNTRY_LIST
    };
  },
  computed: {
    address: {
      get() {
        return this.$store.getters["checkout/getCreditCardAddress"];
      },
      set(address) {
        this.$store.commit("checkout/SET_CREDIT_CARD_ADDRESS", address);
      }
    },
    country: {
      get() {
        return this.$store.getters["checkout/getCreditCardCountry"];
      },
      set(country) {
        this.$store.commit("checkout/SET_CREDIT_CARD_COUNTRY", country);
      }
    },
    postalCode: {
      get() {
        return this.$store.getters["checkout/getCreditCardPostalCode"];
      },
      set(postalCode) {
        this.$store.commit("checkout/SET_CREDIT_CARD_POSTAL_CODE", postalCode);
      }
    },
    city: {
      get() {
        return this.$store.getters["checkout/getCreditCardCity"];
      },
      set(city) {
        this.$store.commit("checkout/SET_CREDIT_CARD_CITY", city);
      }
    },
    province: {
      get() {
        return this.$store.getters["checkout/getCreditCardProvince"];
      },
      set(province) {
        this.$store.commit("checkout/SET_CREDIT_CARD_PROVINCE", province);
      }
    },
    company: {
      get() {
        return this.$store.getters["checkout/getCreditCardCompany"];
      },
      set(company) {
        this.$store.commit("checkout/SET_CREDIT_CARD_COMPANY", company);
      }
    },
    VATNumber: {
      get() {
        return this.$store.getters["checkout/getCreditCardVATNumber"];
      },
      set(VATNumber) {
        this.$store.commit("checkout/SET_CREDIT_CARD_VAT_Number", VATNumber);
      }
    },
    recipientCode: {
      get() {
        return this.$store.getters["checkout/getCreditCardRecipientCode"];
      },
      set(recipientCode) {
        this.$store.commit(
          "checkout/SET_CREDIT_CARD_RECIPIENT_CODE",
          recipientCode
        );
      }
    },
    PEC: {
      get() {
        return this.$store.getters["checkout/getCreditCardPEC"];
      },
      set(PEC) {
        this.$store.commit("checkout/SET_CREDIT_CARD_PEC", PEC);
      }
    },
    purchaseOrderNumber: {
      get() {
        return this.$store.getters["checkout/getCreditCardPurchaseOrderNumber"];
      },
      set(purchaseOrderNumber) {
        this.$store.commit(
          "checkout/SET_CREDIT_CARD_PURCHASE_ORDER_NUMBER",
          purchaseOrderNumber
        );
      }
    },
    acceptTerms: {
      get() {
        return this.$store.getters["checkout/isCreditCardAcceptTermsSelected"];
      },
      set(acceptTerms) {
        this.$store.commit(
          "checkout/SET_CREDIT_CARD_ACCEPT_TERMS",
          acceptTerms
        );
      }
    }
  },
  methods: {
    setFormValidationStatus(valid) {
      this.$emit("setFormValidationStatus", valid);
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched && (dirty || validated)) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style scoped></style>
