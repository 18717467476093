<template>
  <div>
    <div v-if="!hasError" class="mb-3">
      <div>{{ activeProductDescription }}</div>
      <h4>
        TOTAL: {{ activeProductPrice | price
        }}<span class="h4 font-weight-normal">&euro;</span>
      </h4>
      <h6>
        <div>
          <span class="font-weight-bold">Email: </span
          ><span>sb-bwczt1869259@personal.example.com</span>
        </div>
        <div>
          <span class="font-weight-bold">Password: </span><span>%=LT0aM;</span>
        </div>
      </h6>
    </div>
    <div v-else-if="hasError">
      Please try again later.
    </div>
    <b-overlay
      :show="!isReadyToBuy"
      rounded="sm"
      opacity="0.5"
      variant="light"
      class="paypal__overlay"
    >
      <template v-slot:overlay>
        <div></div>
      </template>
      <div ref="paypal" id="paypal__section"></div>
    </b-overlay>
    <!-- DUMMY CHECKBOX FOR PAYPAL VALIDATION -->
    <input
      id="dummyValidationCheck"
      :checked="isReadyToBuy"
      type="checkbox"
      style="display: none"
    />
  </div>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    isFormValid: {
      type: Boolean,
      required: true
    },
    isProjectValid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      clientId:
        "AUy1K9T6k6NP5OAVgYHDNtZUuv8nWT3LbpD223F70FFxDKfjrWmU2-g0hDsYHJJSQ8kctrbcDalJ9IDX",
      loaded: false,
      hasError: false,
      currency: "EUR",
      isValid: false
    };
  },
  async mounted() {
    await this.initPayPalSection();
  },
  computed: {
    isReadyToBuy() {
      return this.isFormValid && this.isProjectValid;
    },
    activeProduct() {
      return this.$store.getters["wizard/getActiveProjectPackageInfo"];
    },
    activeProductPrice() {
      return this.activeProduct.defaultPrice;
    },
    activeProductDescription() {
      return this.activeProduct.name;
    },
    companyName() {
      return this.$store.getters["checkout/getCreditCardCompany"];
    },
    address() {
      return this.$store.getters["checkout/getCreditCardAddress"];
    },
    countryName() {
      return this.$store.getters["checkout/getCreditCardCountryName"];
    },
    countryCode() {
      return this.$store.getters["checkout/getCreditCardCountryCode"];
    },
    postalCode() {
      return this.$store.getters["checkout/getCreditCardPostalCode"];
    },
    city() {
      return this.$store.getters["checkout/getCreditCardCity"];
    },
    province() {
      return this.$store.getters["checkout/getCreditCardProvince"];
    }
  },
  methods: {
    async initPayPalSection() {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.clientId}&currency=${this.currency}`;
      await script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    },
    updateProject() {
      return this.$store.dispatch("project/updateProjectByClientId");
    },
    // TODELETE
    completeProject() {
      this.$store.commit(
        "wizard/SET_ACTIVE_STEP",
        Utils.WIZARD_STEPS.COMPLETED
      );
      return this.updateProject();
    },
    buyProject() {
      return this.$store.dispatch("project/buyProject");
    },
    openErrorDialog() {
      this.$store.commit("error/SET_ERROR", {
        messageText: "dialog.error.paymentError"
      });
    },
    startLoader() {
      this.$store.commit("loader/SET_LOADER", {});
    },
    stopLoader() {
      this.$store.dispatch("loader/close");
    },
    setLoaded() {
      this.loaded = true;
      const updateProject = this.updateProject;
      const completeProject = this.completeProject; // TODELETE
      const buyProject = this.buyProject;
      const startLoader = this.startLoader;
      const stopLoader = this.stopLoader;
      const openErrorDialog = this.openErrorDialog;
      window.paypal
        .Buttons({
          commit: true,
          style: {
            layout: "horizontal",
            tagline: false
          },
          // onInit: (data, actions) => {},
          onClick: (data, actions) => {
            if (document.querySelector("#dummyValidationCheck").checked) {
              return updateProject()
                .then(() => {
                  return actions.resolve();
                })
                .catch(() => {
                  return actions.reject();
                });
              // return true;
            } else return false;
          },
          createOrder: (data, actions) => {
            return actions.order
              .create({
                commit: true,
                payer: {
                  address: {
                    postal_code: this.postalCode,
                    address_line_1: this.address,
                    admin_area_2: this.city,
                    admin_area_1: this.province, // state or provice
                    country: this.countryName,
                    country_code: this.countryCode

                    // address_line_1: "123 Townsend St", // street address
                    // address_line_2: "Floor 6", // apt. ste. bldg
                    // admin_area_2: "San Francisco", // city
                    // admin_area_1: "CA", // state or province (CA lifornia)
                    // postal_code: "94107", // ZIP code or CAP or Post Code
                    // country_code: "IT"
                  }
                },
                purchase_units: [
                  {
                    description: this.activeProductDescription,
                    amount: {
                      currency_code: "EUR",
                      value: this.activeProductPrice
                    },
                    shipping: {
                      name: {
                        full_name: this.companyName
                      },
                      address: {
                        postal_code: this.postalCode,
                        address_line_1: this.address,
                        admin_area_2: this.city,
                        admin_area_1: this.province, // state or provice
                        country: this.countryName,
                        country_code: this.countryCode
                      }
                    }
                    // shipping: {
                    //   address: {
                    //     address_line_1: "2211 N First Street",
                    //     address_line_2: "Building 17",
                    //     admin_area_2: "San Jose",
                    //     admin_area_1: "CA",
                    //     postal_code: "95131",
                    //     country_code: "US"
                    //   }
                    // }
                  }
                ]
              })
              .catch(error => {
                console.log(error);
                openErrorDialog();
              });
          },
          onApprove: async (data, actions) => {
            try {
              startLoader();
              const order = await actions.order.capture();
              this.hasError = false;
              await buyProject();
              await completeProject(); // TODELETE
              console.log(order);
              stopLoader();
              this.$router.push({ name: "SuccessPayment" });
            } catch (error) {
              console.log(error);
              openErrorDialog();
              stopLoader();
            }
          },
          onError: error => {
            this.hasError = true;
            openErrorDialog();
            console.log(error);
          }
        })
        .render(this.$refs.paypal);
    }
  }
};
</script>

<style>
.paypal__overlay {
  z-index: 1;
  max-width: 750px;
}
#paypal__section iframe {
  z-index: 0 !important;
}
</style>
